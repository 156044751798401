import React from 'react'

const Services = () => {
  return (
    <>
      <div className="container mt-5" id="Tour-Packages">
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <div class="site-heading text-center">
            <span class="site-title-tagline">Tour Packages</span>
            <h2 class="site-title">Our Tour Packages</h2>
            <div class="heading-divider"></div>
          </div>
        </div>
      </div>
      <div className="about-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-left wow fadeInLeft" data-wow-delay=".25s">
                <div className="about-img">
                  <img src="/assets/Tour-Images/kaichi-dham2.jpg" />
                </div>

              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-right wow fadeInRight" data-wow-delay=".25s">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline text-center">
                    <i className="flaticon-drive"></i>  Kaichi dham
                  </span>
                  <h2 className="site-title">
                    Kaichi dham tour package <span>1 night 2 days</span>
                  </h2>
                </div>
                <div className="about-list-wrapper">
                  <ul className="about-list list-unstyled">
                    <li>
                      Day 1 = pickup location to kaichi dham temple Evening temple arti.
                    </li>
                    <li>
                      Day 2 = baba ji char dham Yatra kaichi dham kakrighat.hanuman gari  Bhomiya dhaar.
                    </li>
                  </ul>
                </div>
                <a href="#book" className="theme-btn mt-4">Price on request</a>
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-5">
            <div className="col-lg-6">
              <div className="about-right wow fadeInRight" data-wow-delay=".25s">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline text-center">
                    <i className="flaticon-drive"></i>  Almora
                  </span>
                  <h2 className="site-title">
                    Almora tour package  <span>2 night 3 days</span>
                  </h2>
                </div>
                <div className="about-list-wrapper">
                  <ul className="about-list list-unstyled">
                    <li>
                      Day 1= Almora local site seen Nanda Devi temple. Kasar devi temple.mall road.

                    </li>
                    <li>
                      Day 2 = Almora other side site seen  sury katarmal temple Ramkrishna ashram.

                    </li>
                    <li>
                      Day 3 = Almora to dorp haldwani
                    </li>
                  </ul>
                </div>
                <a href="#book" className="theme-btn mt-4">Price on request</a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-left wow fadeInLeft" data-wow-delay=".25s">
                <div className="about-img">
                  <img src="/assets/Tour-Images/Almora.jpg" />
                </div>

              </div>
            </div>
          </div>
          <div className="row align-items-center mt-5">
            <div className="col-lg-6">
              <div className="about-left wow fadeInLeft" data-wow-delay=".25s">
                <div className="about-img">
                  <img src="/assets/Tour-Images/nainital-lake.jpg" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-right wow fadeInRight" data-wow-delay=".25s">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline text-center">
                    <i className="flaticon-drive"></i>  Nainital
                  </span>
                  <h2 className="site-title">
                    Nainital tour package <span>3 night 4 days</span>
                  </h2>
                </div>
                <div className="about-list-wrapper">
                  <ul className="about-list list-unstyled">
                    <li>
                      Day 1= nainital site seen Naina Devi temple eco cave garden tiffin top  Himalaya Darshan Boting Nainital zoo mall rode.
                    </li>
                    <li>
                      Day 2 = Lake tour Bhimtal saat tal nakuchiatal water sporting paraglding.
                    </li>
                    <li>
                      Day 3= mukteshwar temple apple garden tee garden
                    </li>
                    <li>
                      Day 4 =kaichi dham temple fork point
                    </li>
                  </ul>
                </div>
                <a href="#book" className="theme-btn mt-4">Price on request</a>
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-5">
            <div className="col-lg-6">
              <div className="about-right wow fadeInRight" data-wow-delay=".25s">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline text-center">
                    <i className="flaticon-drive"></i>  Jim Corbett park
                  </span>
                  <h2 className="site-title"> Jim Corbett park Tour
                    <span>2 night 3 days </span>
                  </h2>
                </div>
                <div className="about-list-wrapper">
                  <ul className="about-list list-unstyled">
                    <li>
                      Day 1 = Jim Corbett park garjiya temple  Koshi river.
                    </li>
                    <li>
                      Day 2= Jim Corbett morning safari after local site seen elephant raide  Ramnagar market.
                    </li>
                    <li>
                      Day 3 = hunman temple corbett fall  Corbett museum.
                    </li>
                  </ul>
                </div>
                <a href="#book" className="theme-btn mt-4">Price on request</a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-left wow fadeInLeft" data-wow-delay=".25s">
                <div className="about-img">
                  <img src="/assets/Tour-Images/jimmy-carbett.jpeg" />
                </div>

              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-left wow fadeInLeft" data-wow-delay=".25s">
                <div className="about-img">
                  <img src="/assets/Tour-Images/Kasauni.jpg" />
                </div>

              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-right wow fadeInRight" data-wow-delay=".25s">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline text-center">
                    <i className="flaticon-drive"></i> Kausani
                  </span>
                  <h2 className="site-title">
                    Kausani tour package
                    <span>3 night 4 days</span>
                  </h2>
                </div>
                <div className="about-list-wrapper">
                  <ul className="about-list list-unstyled">
                    <li>
                      Day 1 = kathgodam to Ranikhet night stay
                    </li>
                    <li>
                      Day 2 = Ranikhet to kausani site seen Ranikhet.
                    </li>
                    <li> Day 3 = kausani local site seen bajnath temple.tee gardan. anashakti ashram .</li>
                    <li> Day 4 = kausani to kathgodam on the way almora.</li>
                  </ul>
                </div>
                <a href="#book" className="theme-btn mt-4">Price on request</a>
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-5">
            <div className="col-lg-6">
              <div className="about-right wow fadeInRight" data-wow-delay=".25s">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline text-center">
                    <i className="flaticon-drive"></i>  Jageshwar dham
                  </span>
                  <h2 className="site-title">
                    Jageshwar dham yatra <span>2 night 3 days</span>
                  </h2>
                </div>
                <div className="about-list-wrapper">
                  <ul className="about-list list-unstyled">
                    <li>Day 1 = kathgodam to jageshwar temple </li>
                    <li>Day 2 = jageshwar to dol ashram.bell temple.</li>
                    <li>Day 3 = kainchi dham temple and dorp.</li>
                  </ul>
                </div>
                <a href="#book" className="theme-btn mt-4">Price on request</a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-left wow fadeInLeft" data-wow-delay=".25s">
                <div className="about-img">
                  <img src="/assets/Tour-Images/Jageswar-dham.jpg" />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default Services