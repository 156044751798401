import React, { useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

const animationStyles = {
    fadeInUp: {
        animation: 'fadeInUp 3s ease-in-out',
    },
    fadeInRight: {
        animation: 'fadeInRight 3s ease-in-out',
    },
    fadeInLeft: {
        animation: 'fadeInLeft 3s ease-in-out',
    },
};

const HeroSlider = () => {
    const carouselRef = useRef(null);

    const options = {
        loop: true,
        margin: 1,
        items: 1,
        autoplay: true,
        nav: false,
        dots: true,
    };

    const goToPrev = () => {
        if (carouselRef.current) {
            carouselRef.current.prev();
        }
    };

    const goToNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <OwlCarousel
                ref={carouselRef}
                className='owl-theme'
                {...options}
            >
                <div className="hero-single" style={{ background: 'url(/assets/img/slider/slider-1.jpg)', height: '100vh', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-9 mx-auto">
                                <div className="hero-content text-center">
                                    <h6 className="hero-sub-title" style={{ ...animationStyles.fadeInUp, animationDelay: '.25s' }}>
                                        Welcome To Almora Travels!
                                    </h6>
                                    <h1 className="hero-title" style={{ ...animationStyles.fadeInRight, animationDelay: '.50s' }}>
                                        Taxi Available <span>For Rent</span> In Almora
                                    </h1>
                                    <p style={{ ...animationStyles.fadeInLeft, animationDelay: '.75s' }}>
                                        We take care of all bookings pertaining to traveling, accommodation, transfers, sightseeing tours, and all other necessary arrangements for a vacation.
                                    </p>
                                    <div className="hero-btn justify-content-center" style={{ ...animationStyles.fadeInUp, animationDelay: '1s' }}>
                                        <a href="#about" className="theme-btn">About More<i className="fas fa-arrow-right"></i></a>
                                        <a href="#contact" className="theme-btn theme-btn2">Contact Us<i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-single" style={{ background: 'url(/assets/img/slider/slider-2.jpg)', height: '100vh', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-9 mx-auto">
                                <div className="hero-content text-center">
                                    <h6 className="hero-sub-title" style={{ ...animationStyles.fadeInUp, animationDelay: '.25s' }}>
                                        Welcome To Almora Travels!
                                    </h6>
                                    <h1 className="hero-title" style={{ ...animationStyles.fadeInRight, animationDelay: '.50s' }}>
                                        Taxi Available <span>For Rent</span> In Almora
                                    </h1>
                                    <p style={{ ...animationStyles.fadeInLeft, animationDelay: '.75s' }}>
                                        We take care of all bookings pertaining to traveling, accommodation, transfers, sightseeing tours, and all other necessary arrangements for a vacation.
                                    </p>
                                    <div className="hero-btn justify-content-center" style={{ ...animationStyles.fadeInUp, animationDelay: '1s' }}>
                                        <a href="#about" className="theme-btn">About More<i className="fas fa-arrow-right"></i></a>
                                        <a href="#contact" className="theme-btn theme-btn2">Contact Us<i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-single" style={{ background: 'url(/assets/img/slider/slider-3.jpg)', height: '100vh', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-9 mx-auto">
                                <div className="hero-content text-center">
                                    <h6 className="hero-sub-title" style={{ ...animationStyles.fadeInUp, animationDelay: '.25s' }}>
                                        Welcome To Almora Travels!
                                    </h6>
                                    <h1 className="hero-title" style={{ ...animationStyles.fadeInRight, animationDelay: '.50s' }}>
                                        Taxi Available <span>For Rent</span> In Almora
                                    </h1>
                                    <p style={{ ...animationStyles.fadeInLeft, animationDelay: '.75s' }}>
                                        We take care of all bookings pertaining to traveling, accommodation, transfers, sightseeing tours, and all other necessary arrangements for a vacation.
                                    </p>
                                    <div className="hero-btn justify-content-center" style={{ ...animationStyles.fadeInUp, animationDelay: '1s' }}>
                                        <a href="#about" className="theme-btn">About More<i className="fas fa-arrow-right"></i></a>
                                        <a href="#contact" className="theme-btn theme-btn2">Contact Us<i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
            <button
                className="owl-btn"
                style={{
                    background: 'transparent',
                    border: '2px solid #ffb300',
                    color: '#ffb300',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '20px',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '50%',
                    left: '10px',
                    transform: 'translateY(-50%)',
                    zIndex: 10,
                    transition: 'background 0.3s, color 0.3s',
                }}
                onClick={goToPrev}
                onMouseEnter={(e) => { e.currentTarget.style.background = '#ffb300'; e.currentTarget.style.color = '#fff'; }}
                onMouseLeave={(e) => { e.currentTarget.style.background = 'transparent'; e.currentTarget.style.color = '#ffb300'; }}
            >
                <i className="far fa-long-arrow-left"></i>
            </button>
            <button
                className="owl-btn"
                style={{
                    background: 'transparent',
                    border: '2px solid #ffb300',
                    color: '#ffb300',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '20px',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    zIndex: 10,
                    transition: 'background 0.3s, color 0.3s',
                }}
                onClick={goToNext}
                onMouseEnter={(e) => { e.currentTarget.style.background = '#ffb300'; e.currentTarget.style.color = '#fff'; }}
                onMouseLeave={(e) => { e.currentTarget.style.background = 'transparent'; e.currentTarget.style.color = '#ffb300'; }}
            >
                <i className="far fa-long-arrow-right"></i>
            </button>
        </div>
    );
};

export default HeroSlider;
