import React from 'react'

const Header = () => {
    return (
        <>
            <header className="header">
                <div className="header-top">
                    <div className="container">
                        <div className="header-top-wrapper">
                            <div className="header-top-left" style={{ marginLeft: '32px' }}>
                                <div className="header-top-contact">
                                    <ul>
                                        <li>
                                            <a href="mailto:Uk951825@gmail.com">
                                                <i className="far fa-envelopes"></i>
                                                <span> &nbsp; Uk951825@gmail.com</span>
                                            </a>
                                        </li>

                                        <li><a href="tel:9084488229"><i className="far fa-phone-volume"></i> +91 9084488229</a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="header-top-right" style={{ marginRight: '32px' }}>

                                <div className="header-top-social">
                                    <span>Follow Us: </span>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-navigation">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container position-relative">
                            <a className="navbar-brand" href="index.html">
                                <img src="/Almora-log.jpg" style={{height:'80px',width:'100px'}} alt="logo" />
                            </a>
                            <div className="mobile-menu-right">
                                {/* <div className="search-btn">
                                    <button type="button" className="nav-right-link"><i className="far fa-search"></i></button>
                                </div> */}
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-mobile-icon"><i className="far fa-bars"></i></span>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" id="main_nav">
                                <ul className="navbar-nav">
                                    <li className="nav-item"><a className="nav-link" href="#home">Home</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#about-us">About</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#cars">Available Cars</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#Tour-Packages">Tour Packeges</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#features">Our Feature</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#contact">Contact</a></li>
                                </ul>
                                <div className="nav-right">
                                    <div className="nav-right-btn mt-2">
                                        <a href="#book" className="theme-btn"><span className="fas fa-taxi"></span>Book A Car</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    )
}

export default Header