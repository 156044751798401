import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import HeroSlider from './Components/HeroSlider';
import Services from './Components/Services';
import Testimonials from './Components/Testimonials';

function App() {
    return (
        <>
            <Header />
            <HeroSlider />
            <main class="main" id="home">
                <div class="taxi-rate py-120" id="cars">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 mx-auto">
                                <div class="site-heading text-center">
                                    <span class="site-title-tagline">Cars Rate</span>
                                    <h2 class="site-title">Our Cars Rate For You</h2>
                                    <div class="heading-divider"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-lg-4">
                                <div class="rate-item wow fadeInUp" data-wow-delay=".25s">
                                    <div class="rate-header">
                                        <div class="rate-img">
                                            <img src="wgonr.jpg" />
                                        </div>
                                    </div>
                                    <div class="rate-header-content">
                                        <h4>Wagon R</h4>
                                        <p class="rate-duration">Almora Travels</p>
                                    </div>
                                    <div class="rate-content">
                                        <div class="rate-icon">
                                            <img src="/assets/img/icon/taxi-1.svg" />
                                        </div>
                                        <div class="rate-feature">
                                            <ul>
                                                <li><i class="far fa-check-double"></i> Base Charge: <span>Rs 3500</span></li>
                                                <li><i class="far fa-check-double"></i> Capacity: <span>4+1</span></li>
                                                <li><i class="far fa-check-double"></i> Taxi For Local: <span>Yes</span></li>
                                                <li><i class="far fa-check-double"></i> Outstation Taxi : <span>Yes</span></li>

                                            </ul>
                                            <a href="#book" class="theme-btn">Book Now<i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <div class="rate-item wow fadeInDown" data-wow-delay=".25s">
                                    <div class="rate-header">
                                        <div class="rate-img">
                                            <img src="swift.jpg" />
                                        </div>
                                    </div>
                                    <div class="rate-header-content">
                                        <h4>Swift</h4>
                                        <p class="rate-duration">Almora Travels</p>
                                    </div>
                                    <div class="rate-content">
                                        <div class="rate-icon">
                                            <img src="/assets/img/icon/taxi-1.svg" />
                                        </div>
                                        <div class="rate-feature">
                                            <ul>
                                                <li><i class="far fa-check-double"></i> Base Charge: <span> Rs 4000</span></li>
                                                <li><i class="far fa-check-double"></i> Capacity: <span>4+1</span></li>
                                                <li><i class="far fa-check-double"></i> Taxi For Local: <span>Yes</span></li>
                                                <li><i class="far fa-check-double"></i> Outstation Taxi : <span>Yes</span></li>

                                            </ul>
                                            <a href="#book" class="theme-btn">Book Now<i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <div class="rate-item wow fadeInUp" data-wow-delay=".25s">
                                    <div class="rate-header">
                                        <div class="rate-img">
                                            <img src="dzire.jpg" />
                                        </div>
                                    </div>
                                    <div class="rate-header-content">
                                        <h4>swift dzire</h4>
                                        <p class="rate-duration">Almora Travels</p>
                                    </div>
                                    <div class="rate-content">
                                        <div class="rate-icon">
                                            <img src="/assets/img/icon/taxi-1.svg" />
                                        </div>
                                        <div class="rate-feature">
                                            <ul>
                                                <li><i class="far fa-check-double"></i> Base Charge: <span> Rs 4000</span></li>
                                                <li><i class="far fa-check-double"></i> Capacity: <span>4+1</span></li>
                                                <li><i class="far fa-check-double"></i> Taxi For Local: <span>Yes</span></li>
                                                <li><i class="far fa-check-double"></i> Outstation Taxi : <span>Yes</span></li>

                                            </ul>
                                            <a href="#book" class="theme-btn">Book Now<i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-lg-4">
                                <div class="rate-item wow fadeInDown" data-wow-delay=".25s">
                                    <div class="rate-header">
                                        <div class="rate-img">
                                            <img src="ertiga.jpg" />
                                        </div>
                                    </div>
                                    <div class="rate-header-content">
                                        <h4>Ertiga</h4>
                                        <p class="rate-duration">Almora Travels</p>
                                    </div>
                                    <div class="rate-content">
                                        <div class="rate-icon">
                                            <img src="/assets/img/icon/taxi-1.svg" />
                                        </div>
                                        <div class="rate-feature">
                                            <ul>
                                                <li><i class="far fa-check-double"></i> Base Charge: <span> Rs1800</span></li>
                                                <li><i class="far fa-check-double"></i> Capacity: <span>6+1</span></li>
                                                <li><i class="far fa-check-double"></i> Taxi For Local: <span>Yes</span></li>
                                                <li><i class="far fa-check-double"></i> Outstation Taxi : <span>Yes</span></li>

                                            </ul>
                                            <a href="#book" class="theme-btn">Book Now<i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <div class="rate-item wow fadeInUp" data-wow-delay=".25s">
                                    <div class="rate-header">
                                        <div class="rate-img">
                                            <img src="innova.jpg" />
                                        </div>
                                    </div>
                                    <div class="rate-header-content">
                                        <h4>Innova</h4>
                                        <p class="rate-duration">Almora Travels</p>
                                    </div>
                                    <div class="rate-content">
                                        <div class="rate-icon">
                                            <img src="/assets/img/icon/taxi-1.svg" />
                                        </div>
                                        <div class="rate-feature">
                                            <ul>
                                                <li><i class="far fa-check-double"></i> Base Charge: <span>Rs 6000</span></li>
                                                <li><i class="far fa-check-double"></i> Capacity: <span>6+1</span></li>
                                                <li><i class="far fa-check-double"></i> Taxi For Local: <span>Yes</span></li>
                                                <li><i class="far fa-check-double"></i> Outstation Taxi : <span>Yes</span></li>
                                            </ul>
                                            <a href="#book" class="theme-btn">Book Now<i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <div class="rate-item wow fadeInDown" data-wow-delay=".25s">
                                    <div class="rate-header">
                                        <div class="rate-img">
                                            <img src="crysta.jpg" />
                                        </div>
                                    </div>
                                    <div class="rate-header-content">
                                        <h4>innova crysta</h4>
                                        <p class="rate-duration">Almora Travels</p>
                                    </div>
                                    <div class="rate-content">
                                        <div class="rate-icon">
                                            <img src="/assets/img/icon/taxi-1.svg" />
                                        </div>
                                        <div class="rate-feature">
                                            <ul>
                                                <li><i class="far fa-check-double"></i> Base Charge: <span> Rs 9000</span></li>
                                                <li><i class="far fa-check-double"></i> Capacity: <span>6+1</span></li>
                                                <li><i class="far fa-check-double"></i> Taxi For Local: <span>Yes</span></li>
                                                <li><i class="far fa-check-double"></i> Outstation Taxi : <span>Yes</span></li>

                                            </ul>
                                            <a href="#book" class="theme-btn">Book Now<i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <Services />
                            </div>

                        </div>
                    </div>
                </div>
                <div class="contact-wrapper" id="book">
                    <div class="row">
                        <div class="col-lg-6 align-self-center">
                            <div class="contact-img">
                                <img src="/assets/img/contact/01.jpg" />
                            </div>
                        </div>
                        <div class="col-lg-6 align-self-center">
                            <div class="contact-form">
                                <div class="contact-form-header">
                                    <h2>Book Your Car</h2>
                                    <p>Please fill this form to book your car! </p>
                                    <p style={{ color: 'red' }}>
                                    </p>
                                </div>
                                <form method="post" action="sendemail.php" id="contact-form">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" name="name" placeholder="Your Name" required />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="email" class="form-control" name="email" placeholder="Your Email" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" name="mobile" placeholder="Mobile" required />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <select class="form-control" name="car">
                                                    <option value="WAGON R">WAGON R
                                                    </option>
                                                    <option value="SWIFT">SWIFT</option>
                                                    <option value="SWIFT DZIRE">SWIFT DZIRE</option>
                                                    <option value="ERTIGA">ERTIGA</option>
                                                    <option value="BREZZA">BREZZA</option>
                                                    <option value="INNOVA CRYSTA">INNOVA CRYSTA</option>
                                                    <option value="CIAZ">CIAZ</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Booking Date</label>
                                        <input type="date" class="form-control" name="bookingdate" placeholder="Date" required />
                                    </div>

                                    <button type="submit" class="theme-btn">Book Now <i class="far fa-paper-plane"></i></button>
                                    <div class="col-md-12 mt-3">
                                        <div class="form-messege text-success"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="counter-area">
                    <div class="container">
                        <div class="counter-wrapper">
                            <div class="row">
                                <div class="col-lg-3 col-sm-6">
                                    <div class="counter-box">
                                        <div class="icon">
                                            <img src="/assets/img/icon/taxi-1.svg" />
                                        </div>
                                        <div>
                                            <span class="counter" data-count="+" data-to="50" data-speed="3000">50</span>
                                            <h6 class="title">+ Available Cars </h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                    <div class="counter-box">
                                        <div class="icon">
                                            <img src="/assets/img/icon/happy.svg" />
                                        </div>
                                        <div>
                                            <span class="counter" data-count="+" data-to="800" data-speed="3000">800</span>
                                            <h6 class="title">+ Happy Clients</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                    <div class="counter-box">
                                        <div class="icon">
                                            <img src="/assets/img/icon/driver.svg" />
                                        </div>
                                        <div>
                                            <span class="counter" data-count="+" data-to="20" data-speed="3000">20</span>
                                            <h6 class="title">+ Our Drivers</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                    <div class="counter-box">
                                        <div class="icon">
                                            <img src="/assets/img/icon/trip.svg" />
                                        </div>
                                        <div>
                                            <span class="counter" data-count="+" data-to="800" data-speed="3000">800</span>
                                            <h6 class="title">+ Road Trip Done</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="feature-area feature-bg py-120" id="features">
                    <div class="container mt-150">
                        <div class="row">
                            <div class="col-lg-6 mx-auto">
                                <div class="site-heading text-center">
                                    <span class="site-title-tagline">Feature</span>
                                    <h2 class="site-title text-white">Our Awesome Feature</h2>
                                    <div class="heading-divider"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-lg-3">
                                <div class="feature-item wow fadeInUp" data-wow-delay=".25s">
                                    <div class="feature-icon">
                                        <img src="/assets/img/icon/taxi-safety.svg" />
                                    </div>
                                    <div class="feature-content">
                                        <h4>Safety Guarantee</h4>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-3">
                                <div class="feature-item mt-lg-5 wow fadeInDown" data-wow-delay=".25s">
                                    <div class="feature-icon">
                                        <img src="/assets/img/icon/pickup.svg" />
                                    </div>
                                    <div class="feature-content">
                                        <h4>Fast Pickup</h4>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-3">
                                <div class="feature-item wow fadeInUp" data-wow-delay=".25s">
                                    <div class="feature-icon">
                                        <img src="/assets/img/icon/money.svg" />
                                    </div>
                                    <div class="feature-content">
                                        <h4>Affordable Rate</h4>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-3">
                                <div class="feature-item mt-lg-5 wow fadeInDown" data-wow-delay=".25s">
                                    <div class="feature-icon">
                                        <img src="/assets/img/icon/support.svg" />
                                    </div>
                                    <div class="feature-content">
                                        <h4>24/7 Support</h4>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="about-area py-120" id="about-us">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="about-left wow fadeInLeft" data-wow-delay=".25s">
                                    <div class="about-img">
                                        <img src="/assets/img/about/01.png" />
                                    </div>
                                    <div class="about-experience">
                                        <div class="about-experience-icon">
                                            <img src="/assets/img/icon/taxi-booking.svg" />
                                        </div>
                                        <b>10 Years Of <br /> Quality Service</b>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="about-right wow fadeInRight" data-wow-delay=".25s">
                                    <div class="site-heading mb-3">
                                        <span class="site-title-tagline justify-content-start">
                                            <i class="flaticon-drive"></i> About Us
                                        </span>
                                        <h2 class="site-title">
                                            Self-Drive <span> Car Provide</span> In Almora
                                        </h2>
                                    </div>
                                    <p class="about-text">
                                        Setup and operating from Margaon  (Almora), Almora Travels is one of the most prominent tour operators in India. We take care of all bookings pertaining to traveling, accommodation, transfers, sightseeing tours, and all other necessary arrangements for a vacation.
                                    </p>
                                    <div class="about-list-wrapper">
                                        <ul class="about-list list-unstyled">
                                            <li>
                                                From pick up to drop.
                                            </li>
                                            <li>
                                                From deciding the best time for your journey to the safety concerns.
                                            </li>
                                            <li>
                                                We get you covered with the best quality.
                                            </li>
                                        </ul>
                                    </div>
                                    <a href="#Tour-Packages" class="theme-btn mt-4">Discover More<i class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="testimonial-area py-120" id="testimonials">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 mx-auto">
                                <div class="site-heading text-center">
                                    <span class="site-title-tagline"><i class="flaticon-drive"></i> Testimonials</span>
                                    <h2 class="site-title text-white">What Our Client <span>Say's</span></h2>
                                    <div class="heading-divider"></div>
                                </div>
                            </div>
                        </div>
                        <div class="testimonial-slider owl-carousel owl-theme">
                            <div class="testimonial-single">
                                <div class="testimonial-content">

                                    <div class="testimonial-author-info">
                                        <h4>Sylvia Green</h4>
                                        <p>Customer</p>
                                    </div>
                                </div>
                                <div class="testimonial-quote">
                                    <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                    <p>
                                        There are many variations of words suffered available to the have majority but the majority
                                        suffer to alteration injected hidden the middle text.
                                    </p>
                                </div>
                                <div class="testimonial-rate">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </div>
                            </div>
                            <div class="testimonial-single">
                                <div class="testimonial-content">

                                    <div class="testimonial-author-info">
                                        <h4>Gordo Novak</h4>
                                        <p>Customer</p>
                                    </div>
                                </div>
                                <div class="testimonial-quote">
                                    <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                    <p>
                                        There are many variations of words suffered available to the have majority but the majority
                                        suffer to alteration injected hidden the middle text.
                                    </p>
                                </div>
                                <div class="testimonial-rate">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </div>
                            </div>
                            <div class="testimonial-single">
                                <div class="testimonial-content">

                                    <div class="testimonial-author-info">
                                        <h4>Reid Butt</h4>
                                        <p>Customer</p>
                                    </div>
                                </div>
                                <div class="testimonial-quote">
                                    <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                    <p>
                                        There are many variations of words suffered available to the have majority but the majority
                                        suffer to alteration injected hidden the middle text.
                                    </p>
                                </div>
                                <div class="testimonial-rate">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </div>
                            </div>
                            <div class="testimonial-single">
                                <div class="testimonial-content">

                                    <div class="testimonial-author-info">
                                        <h4>Parker Jime</h4>
                                        <p>Customer</p>
                                    </div>
                                </div>
                                <div class="testimonial-quote">
                                    <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                    <p>
                                        There are many variations of words suffered available to the have majority but the majority
                                        suffer to alteration injected hidden the middle text.
                                    </p>
                                </div>
                                <div class="testimonial-rate">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </div>
                            </div>
                            <div class="testimonial-single">
                                <div class="testimonial-content">

                                    <div class="testimonial-author-info">
                                        <h4>Heruli Nez</h4>
                                        <p>Customer</p>
                                    </div>
                                </div>
                                <div class="testimonial-quote">
                                    <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                    <p>
                                        There are many variations of words suffered available to the have majority but the majority
                                        suffer to alteration injected hidden the middle text.
                                    </p>
                                </div>
                                <div class="testimonial-rate">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <Testimonials /> */}
                <div class="cta-area" id="contact">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-7 text-center text-lg-start">
                                <div class="cta-text cta-divider">
                                    <h1>Book Your Car It's Simple And Affordable</h1>
                                    <p>From pick up to drop, and from deciding the best time for your journey to the safety concerns, we get you covered with the best quality.</p>
                                </div>
                            </div>
                            <div class="col-lg-5 text-center text-lg-end">
                                <div class="mb-20">
                                    <a href="#" class="cta-number"><i class="far fa-headset"></i> +91 9084488229</a>
                                </div>
                                <div class="cta-btn">
                                    <a href="#" class="theme-btn">Book Your Car<i class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}

export default App;
